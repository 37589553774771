body {
  background-color: #e6bcca;
  font-family: 'Comic Sans MS', 'Arial', sans-serif;
}

.cute-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://example.com/cute-background.gif') repeat;
  z-index: -1;
  opacity: 0.1;
}

h1 {
  color: #ff69b4;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px #ffb6c1;
}

h4 {
  color: #ff1493;
}

button {
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

.progress-bar {
  font-weight: bold;
}

.card {
  background-color: #ffe4e1;
  border-radius: 20px;
}

.styled-image {
  max-width: 50%; /* Bild passt sich der Breite des Containers an */
  height: auto;    /* Bewahrt das Seitenverhältnis */
  object-fit: cover; /* Optional: Schneidet das Bild passend zu, falls nötig */
}
/* In deiner App.css Datei */
@keyframes fadeInAndMove {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Startet leicht oberhalb */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Endet in der normalen Position */
  }
}

.animated-kitty {
  animation: fadeInAndMove 2s ease-out forwards; /* Animation auf das Bild anwenden */
}

